import useTheme from 'hooks/useTheme'
import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Cutting-edge Central Banking Hub:'),
  headingSubText: t('Discover EramDex, the Most Trusted Decentralized Hub in the World.'),
  colorHeadingText: useTheme().isDark ? 'white' : '#122549',
  firstColorText: useTheme().isDark ? 'white' : '#122549',
  colorHeadingSubText: '#08B0FF',
  bodyText: t('Trade any thing. No registration, no hassle'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  // secondaryButton: {
  //   to: '#',
  //   text: t('Learn'),
  //   external: true,
  // },
  images: {
    path: '/images/home/home4/',
    attributes: [{ src: 'image1', alt: 'image' }],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('EramDex Hub'),
  colorHeadingText: useTheme().isDark ? 'white' : '#122549',
  firstColorText: '#08B0FF',
  bodyText: t('Invest wisely with crypto earn smart money'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  // secondaryButton: {
  //   to: '#',
  //   text: t('Learn'),
  //   external: true,
  // },
  images: {
    path: '/images/home/home5/',
    attributes: [{ src: 'image1', alt: t('image') }],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('ERMD EramDex'),
  colorHeadingText: useTheme().isDark ? "white" : '#122549',
  firstColorText: '#08B0FF',
  bodyText: t(
    'Experience the revolution of ERMD crypto, transforming the landscape of financial independence.',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x54BA4075cEb2E31Ed20E0Cc442EA8ca0f3479CCf&chainId=721529',
    text: t('Buy ERMD'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/tokenomics/cake',
  //   text: t('Learn'),
  //   external: true,
  // },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'image1', alt: t('image') },
    ],
  },
})
